:root {
  /*--color-principal: rgb(255, 1, 162);*/
  --color-accent: #5a2e53;
  --color-bg: #efefef;
  --color-txt: var(--color-accent);
  --color-footer: #f9ddeb;
  --color-principal: var(--color-footer);
}
@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container_principal {
  position: relative;
  background: #fff;
}
#loader {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background: #fff;
  z-index: 99;
  transition: all 0.3s ease-in-out;
  border: 1px solid #efefef;
}
.loader_container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
}
.loader_logo {
  width: 70%;
  max-width: 500px;
  margin-bottom: 3rem;
}
.loader_loading {
  display: flex;
  align-items: center;
  color: var(--color-txt);
}
.loader_loading_circle {
  display: block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.25rem solid #dfdfdf;
  border-radius: 50%;
  margin-right: 0.8rem;
  animation: loader 2s infinite;
}
.loader_loading_circle::after {
  display: block;
  content: "";
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  width: 100%;
  height: 100%;
  border: 0.25rem solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
}
.productos_header {
  width: 100%;
  display: flex;
  position: sticky;
  top: 0;
  padding: 2rem 1rem 1rem;
  background: #fff;
  color: #c25795;
  font-size: 1rem;
  text-transform: capitalize;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border: 1px solid #efefef;
}
.productos_header h1 {
  font-size: 1.3rem;
  font-weight: 500;
}

.productos_grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  flex-wrap: wrap;
  padding: 2rem;
  grid-gap: 2rem;
}

.producto_card {
  position: relative;
  width: 100%;
  /*height: fit-content;*/
  border: 0px solid #eaeaea;
  display: flex;
  flex-direction: column;
  background: var(--color-bg);
  color: var(--color-txt);
  font-size: 1rem;
  border-radius: 1rem;
  overflow: hidden;
}
.producto_header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
}
.producto_header_nombre {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 1rem;
  justify-content: center;
}
.producto_header_nombre h3 {
  font-size: 1em;
}
.producto_header_nombre span {
  display: block;
  width: fit-content;
  font-size: 0.8em;
  color: var(--color-txt);
  padding-right: 1rem;
}
.producto_header_logo {
  width: 4rem;
}

.producto_img_container {
  display: flex;
  position: relative;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  padding-bottom: 2rem;
}
.producto_img {
  flex-grow: 1;
}
.producto_img img {
  width: 100%;
  height: auto;
}
.producto_stock {
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-footer);
  transition: all 0.2s ease-in-out;
}
.producto_stock_open {
  color: #fff;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  transform: translateY(-100%);
  transition: all 0.2s ease-in-out;
}
.producto_stock_open span {
  cursor: pointer;
  background: var(--color-accent);
  padding: 0.2em 0.5em 0.2em 1.5em;
  border-radius: 5rem 5rem 0rem 0rem;
  transition: all 0.2s ease-in-out;
}
.producto_stock_open span:after {
  content: "▲";
  padding: 0em 0.5em;
  display: inline-block;
}
.producto_stock.opened {
  top: 0 !important;
}
.producto_stock.opened .producto_stock_open {
  transform: translateY(0);
}
.producto_stock.opened .producto_stock_open span {
  border-radius: 0rem 0rem 5rem 5rem;
}
.producto_stock.opened .producto_stock_open span:after {
  transform: rotate(180deg);
}

.producto_stock table {
  padding: 2rem;
  width: 100%;
  height: fit-content;
  display: block;
  overflow-y: auto;
}
.producto_stock table td {
  width: 100%;
  padding: 0.15em;
}
.producto_stock table td.out_stock {
  opacity: 0.2;
}
.producto_stock table tr {
  margin-top: 0.5em;
}
.producto_stock table tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.03);
}
.producto_stock table thead tr {
  background: transparent !important;
}
.producto_stock table td:not(:first-child) {
  text-align: center;
}

.producto_variacion {
  display: flex;
  width: 4rem;
  flex-direction: column;
  margin: 0 1rem 1rem 0;
}
.producto_variacion_atributo {
  width: 4rem;
  margin-right: 1rem;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 0.5em;
}
.producto_variacion_atributo_color {
  border-radius: 50%;
  background: black;
  width: 2rem;
  height: 2rem;
}
.producto_etiqueta_container {
  position: absolute;
  font-size: 0.8em;
  top: 0;
  left: 0;
}
.producto_etiqueta_container_valor {
  display: block;
  padding: 0.2rem 0.5rem 0.2rem 1rem;
  margin-bottom: 1rem;
  background: #ff6666;
  color: #fff;
}

.producto_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-footer);
  width: 100%;
  padding: 1rem;
  font-size: 0.8em;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.producto_footer_desc {
  flex-grow: 1;
  padding: 0 1rem;
}
.producto_footer_precio {
  width: fit-content;
  text-align: center;
  font-size: 0.9rem;
  color: var(--color-accent);
}
.producto_footer_precio_descuento {
  text-decoration: line-through;
  font-size: 1.1em;
}
.producto_footer_precio_currency {
  font-size: 0.5em;
}
.producto_footer_precio_valor {
  background: var(--color-accent);
  color: #fff;
  border-radius: 2rem;
  padding: 0.25em 0.5em;
  font-size: 1.1em;
  font-family: 700;
}
.producto_footer_precio_texto {
  font-weight: 700;
  font-size: 0.8em;
}

@media (min-width: 600px) {
  .productos_grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .producto_card {
    max-width: 400px;
  }
  .productos_header h1 {
    font-size: 1.5rem;
  }
}
@media (min-width: 900px) {
  .productos_grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .productos_header h1 {
    font-size: 1.7rem;
  }
}
@media (min-width: 1200px) {
  .productos_grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
